import React, {
    useEffect,
    useCallback,
    useState,
    useReducer,
    useRef,
  } from "react";
  
  import { useNavigate, useLocation } from "react-router-dom";
  // import { useProSidebar } from "react-pro-sidebar";
  import { CompSideBar } from "../components/sidebar";
  import { Line } from "react-chartjs-2";
  import { Modal, Row, Col, Card, Button, Form, Stack } from "react-bootstrap";
  import { HelioPay } from "@heliofi/react";
import { ToastContainer, toast } from "react-toastify";
  import Tab from 'react-bootstrap/Tab';
  import Tabs from 'react-bootstrap/Tabs';
  import Carousel from 'react-grid-carousel'
  import reach_us_img from "../images/contact-us.png";
  import checkoutImg from "../images/checkout-img.png";
  import storeAvatar from "../images/store-avatar.png";
  import storeImg1 from "../images/store-img1.png";
  import storeImg2 from "../images/store-img2.png";
  import storeImg3 from "../images/store-img3.png";
  import storeImg4 from "../images/store-img4.png";
  import solanalogo from "../images/icons/solanalogo.png";
  import facebook from "../images/icons/facebook-store.png";
  import insta from "../images/icons/insta-store.png";
  import twitter from "../images/icons/twitter-store.png";
  import freeShipping from "../images/icons/free-shipping.png";
  import easyReturn from "../images/icons/easy-return.png";
  import securePayment from "../images/icons/secure-payment.png";
  import support from "../images/icons/support.png";
  import cartArrow from "../images/icons/cart-arrow.png";
  import searchIcon from "../images/icons/search-icon.png";
  import quantityminus from "../images/icons/quantityminus.png";
  import quantityplus from "../images/icons/quantityplus.png";


  import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
  import {
    WalletDisconnectButton,
    WalletMultiButton,
  } from "@solana/wallet-adapter-react-ui";

  import "bootstrap/dist/css/bootstrap.min.css";
  import "./shopping-cart.css";
  import "./my-cart.css";

export const MyAddress = ({publicKey}) => {
    const navigate = useNavigate();
    // const { collapseSidebar } = useProSidebar();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    const [savedAddress, setSavedAddress] = useState(false);
    const [userAddress, setUserAddress] = useState({});
    const [isEditAddress, setIsEditAddress] = useState(false);
    const [editAddress, setEditAddress] = useState({});
    const [savedUserData, setSavedUserData] = useState([{}]);
    const [activeAddress, setActiveAddress] = useState({});
    const [otherAddress, setOtherAddress] = useState([{}]);
    const [userId, SetUserId] = useState("");
    const [allProduct, setAllProduct] = useState([]);
    const [productQuantity, setProductQuantity] = useState(1);
    const [subTotal, setSubTotal] = useState(0.0);
    const [shippingCost, setShippingCost] = useState(0);
    const [tax, setTax] = useState(18);
    const [taxValue, setTaxValue] = useState(0);
  
  
    const location = useLocation();
    const isWebView = location.search.includes('view=webview');
  
    const AddAddress = async (e) => {
      setSavedAddress(false);
    };
    const EditAddress = async (e) => {
      setEditAddress(savedUserData[parseInt(e.target.id)]);
      setIsEditAddress(true);
    };
    function handleEditAddressData(e) {
      const { name, value } = e.target;
      console.log(name, value);
      const userAddressData = { ...editAddress };
      if (name == "country") {
        if (value == "india") {
          setShippingCost(parseInt(allProduct[0]["shippingCostIndia"]));
        } else {
          setShippingCost(parseInt(allProduct[0]["shippingCostOtherCountry"]));
        }
      }
      userAddressData[name] = value;
      setEditAddress(userAddressData);
      console.log(userAddress);
    }
    const saveUserAddress = async (e) => {
        console.log(userAddress);
        if (userAddress["isSaved"] == 1) {
          userAddress["walletID"] = "93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi";
          const response = await fetch(
            process.env.REACT_APP_API_URL + "create_userAddress",
            {
              method: "POST",
              mode: "cors",
              headers: new Headers({ "content-type": "application/json" }),
              body: JSON.stringify(userAddress),
            }
          );
          if (response.status === 200) {
            let product_resp = await response.json();
            console.log(product_resp);
            setSavedAddress(true);
            var r = window.confirm("Address Saved Successfully!");
            if (r == true) {
              getUserAddress(true);
            }
          } else {
            console.log("error");
          }
        }
      };
    const saveEditUserAddress = async (e) => {
      console.log(editAddress);
      editAddress["walletID"] = "93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi";
      editAddress['updateActive'] = "0"
      const response = await fetch(
        process.env.REACT_APP_API_URL + "updateUserAddress",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify(editAddress),
        }
      );
      if (response.status === 200) {
        let product_resp = await response.json();
        console.log(product_resp);
        setSavedAddress(true);
        setIsEditAddress(false);
        var r = window.confirm("Address Saved Successfully!");
        if (r == true) {
          getUserAddress(true);
        }
      } else {
        console.log("error");
      }
    };
    function handleAddressData(e) {
        const { name, value } = e.target;
        console.log(name, value);
        const userAddressData = { ...userAddress };
        if (name == "country") {
          if (value == "india") {
            setShippingCost(parseInt(allProduct[0]["shippingCostIndia"]));
          } else {
            setShippingCost(parseInt(allProduct[0]["shippingCostOtherCountry"]));
          }
        }
        if (name == "isSaved") {
          if (e.target.checked) {
            userAddressData[name] = 1;
          } else {
            userAddressData[name] = 0;
          }
        } else {
          userAddressData[name] = value;
        }
    
        setUserAddress(userAddressData);
        console.log(userAddress);
      }
    const getUserAddress = async (isUpdate) => {
        const response = await fetch(process.env.REACT_APP_API_URL + "getUserAddress", {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            walletID: "93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi",
          }),
        });
        if (response.status === 200) {
          let user_resp = await response.json();
          console.log(user_resp);
          const otherAdd : Object[] = [];
          user_resp["userAddress"].forEach(function(addressElement, addressIndex){
            if(addressElement['isActive']==1){
                setActiveAddress(addressElement);
            }
            else{
                otherAdd.push(addressElement);
            }
          })
          setSavedUserData(otherAdd);
          setOtherAddress(otherAdd);
          setSavedAddress(true);
          
          
        } else {
          console.log("error");
        }
      };
  
    const getProductsByUserID = async () => {
        console.log(userId);
        if(userId){
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "getShoppingCart",
                    { 
                        method: "POST",
                        mode: "cors",
                        headers: new Headers({ "content-type": "application/json" }),
                        body: JSON.stringify({ walletId: userId }),
                    }
                );
                if (response.status === 200) {
                    const data = await response.json();
                    console.log(data);
                    setAllProduct(data['productData']);
                    setSubTotal(data['cartSubTotal']);
                } else {
                    console.log(response.status);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };
    const selectAddress = async (e) => {
        console.log(e.target.id)
        const response = await fetch(
            process.env.REACT_APP_API_URL + "updateUserAddress",
            {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ id: e.target.id, walletID: "93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi", updateActive: "1" }),
            }
        );
        if (response.status === 200) {
            getUserAddress(true);
        } else {
            const data = await response.json();
            console.log(data);
        }
    }
    const RemoveAddress = async (e) => {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "deleteUserAddress",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ id: e.target.id }),
          }
        );
        if (response.status === 200) {
          getUserAddress(true);
        } else {
          console.log("error");
        }
      };
      const redirect = () => {
        navigate("/cart-check-out");
      }
    // useEffect(() => {
    //     // var url_path = window.location.pathname;
    //     // console.log(url_path);
    //     // var product_id = url_path.split("/")[2];
    //     // console.log(product_id);
    //     SetUserId("93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi");

    //   }, [navigate]);
    
      useEffect(() => {
        async function data() {
            SetUserId("93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi");
            await getUserAddress(false);
        }
        console.log("use effect running");
        data();
      }, [navigate, userId]);
      
    return(
        <>
            {true ? (
            <>
                <div>
                    <CompSideBar publicKey={publicKey} />
                </div>
                <div className="mainpage-dashboard">
                    <div className="cart-head">
                        <Row>
                            <Col sm={12}>
                                {isEditAddress == false && savedAddress ? (
                                    <>
                                    <div className="checkout-subhead">
                                        <h2>
                                        Saved Address{" "}
                                        <span onClick={AddAddress}>Add new Address</span>
                                        </h2>
                                    </div>
                                    <h3 className="address-del">Delivering Order to</h3>
                                    {activeAddress && (
                                    <>
                                        <div id={activeAddress["id"]} className="address-div active-address" >
                                            <div className="address-details">
                                                <div className="address-head-action">
                                                    {/* <h3 id={index.toString()} className="address-edit" onClick={EditAddress} > Edit </h3> */}
                                                    <h3 id={activeAddress["id"]} onClick={RemoveAddress} className="address-delete" > Remove </h3>
                                                </div>
                                                <h3>{activeAddress["name"]}</h3>
                                                <h3>
                                                {activeAddress["addressLine1"]},{" "}
                                                {activeAddress["addressLine2"]},{" "}
                                                {activeAddress["city"]},{" "} {activeAddress["postalCode"]},{" "}
                                                {activeAddress["country"]},{" "}{activeAddress["mobileNumber"]},{" "}{activeAddress["emailAddress"]}
                                                </h3>
                                            </div>
                                        </div>
                                    </>
                                    )}
                                    <h3 className="address-del">Other Saved Address</h3>
                                    {otherAddress.map((eachUser, index) => (
                                        <div key={index} id={eachUser["id"]} className="address-div">
                                        <div className="address-details">
                                            <div className="address-head-action">
                                            <h3 id={index.toString()} className="address-edit" onClick={EditAddress} > Edit </h3>
                                            <h3 id={eachUser["id"]} onClick={RemoveAddress} className="address-delete" > Remove </h3>
                                            </div>
                                            <h3 id={eachUser["id"]} onClick={selectAddress}>{eachUser["name"]}</h3>
                                            <h3 id={eachUser["id"]} onClick={selectAddress}>
                                            {eachUser["addressLine1"]},{" "}
                                            {eachUser["addressLine2"]},{" "}
                                            {eachUser["city"]}, {eachUser["postalCode"]},{" "}
                                            {eachUser["country"]},{" "}{eachUser["mobileNumber"]},{" "}{eachUser["emailAddress"]}
                                            </h3>
                                        </div>
                                        </div>
                                    ))}
                                    
                                    </>
                                ) : (
                                    <>
                                    <div className="checkout-subhead">
                                        <h2>Shipping Information</h2>
                                    </div>
                                    {isEditAddress ? (
                                        <>
                                        <div>
                                            <div className="check-out-form">
                                            <Form.Control
                                                className="form-input"
                                                size="lg"
                                                name="name"
                                                type="text"
                                                placeholder="Name *"
                                                onChange={(e) => handleEditAddressData(e)}
                                                value={editAddress["name"]}
                                            />
                                            <Form.Control
                                                as="select"
                                                className="form-input"
                                                name="country"
                                                size="lg"
                                                type="text"
                                                placeholder="Select Country *"
                                                onChange={(e) => handleEditAddressData(e)}
                                            >
                                                <option>Select Country *</option>
                                                <option
                                                value="india"
                                                selected={
                                                    editAddress["country"] == "india"
                                                    ? true
                                                    : false
                                                }
                                                >
                                                India
                                                </option>
                                                <option
                                                value="usa"
                                                selected={
                                                    editAddress["country"] == "usa"
                                                    ? true
                                                    : false
                                                }
                                                >
                                                USA
                                                </option>
                                            </Form.Control>
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="addressLine1"
                                                type="text"
                                                placeholder="Address Line 1 *"
                                                onChange={(e) => handleAddressData(e)}
                                                value={editAddress["addressLine1"]}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="addressLine2"
                                                type="text"
                                                placeholder="Address Line 2"
                                                onChange={(e) => handleEditAddressData(e)}
                                                value={editAddress["addressLine2"]}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="city"
                                                type="text"
                                                placeholder="City *"
                                                onChange={(e) => handleEditAddressData(e)}
                                                value={editAddress["city"]}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="landmark"
                                                type="text"
                                                placeholder="Landmark (Optional)"
                                                onChange={(e) => handleEditAddressData(e)}
                                                value={editAddress["landmark"]}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="postalCode"
                                                type="text"
                                                placeholder="Postal Code *"
                                                onChange={(e) => handleEditAddressData(e)}
                                                value={editAddress["postalCode"]}
                                            />
                                            </div>
                                            <div className="checkout-subhead checkout-subhead-contact">
                                            <h2>Contact Information</h2>
                                            </div>
                                            <div className="check-out-form">
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="mobileNumber"
                                                type="text"
                                                placeholder="Mobile Number *"
                                                onChange={(e) => handleEditAddressData(e)}
                                                value={editAddress["mobileNumber"]}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="emailAddress"
                                                type="text"
                                                placeholder="Email Address *"
                                                onChange={(e) => handleEditAddressData(e)}
                                                value={editAddress["emailAddress"]}
                                            />
                                            <Button
                                                className="shadow-btn shadow-btn-width"
                                                variant="primary"
                                                onClick={saveUserAddress}
                                                >
                                                Save Address
                                            </Button>
                                            </div>
                                        </div>
                                        </>
                                    ) : (
                                        <>
                                        <div>
                                            <div className="check-out-form">
                                            <Form.Control
                                                className="form-input"
                                                size="lg"
                                                name="name"
                                                type="text"
                                                placeholder="Name *"
                                                onChange={(e) => handleAddressData(e)}
                                            />
                                            <Form.Control
                                                as="select"
                                                className="form-input"
                                                name="country"
                                                size="lg"
                                                type="text"
                                                placeholder="Select Country *"
                                                onChange={(e) => handleAddressData(e)}
                                            >
                                                <option selected>Select Country *</option>
                                                <option value="india">India</option>
                                                <option value="usa">USA</option>
                                            </Form.Control>
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="addressLine1"
                                                type="text"
                                                placeholder="Address Line 1 *"
                                                onChange={(e) => handleAddressData(e)}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="addressLine2"
                                                type="text"
                                                placeholder="Address Line 2"
                                                onChange={(e) => handleAddressData(e)}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="city"
                                                type="text"
                                                placeholder="City *"
                                                onChange={(e) => handleAddressData(e)}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="landmark"
                                                type="text"
                                                placeholder="Landmark (Optional)"
                                                onChange={(e) => handleAddressData(e)}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="postalCode"
                                                type="text"
                                                placeholder="Postal Code *"
                                                onChange={(e) => handleAddressData(e)}
                                            />
                                            </div>
                                            <div className="checkout-subhead checkout-subhead-contact">
                                            <h2>Contact Information</h2>
                                            </div>
                                            <div className="check-out-form">
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="mobileNumber"
                                                type="text"
                                                placeholder="Mobile Number *"
                                                onChange={(e) => handleAddressData(e)}
                                            />
                                            <Form.Control
                                                size="lg"
                                                className="form-input"
                                                name="emailAddress"
                                                type="text"
                                                placeholder="Email Address *"
                                                onChange={(e) => handleAddressData(e)}
                                            />
                                            </div>
                                            <Form.Check
                                            type="checkbox"
                                            id="save-info"
                                            name="isSaved"
                                            label="Save this information for next time"
                                            className="form-check"
                                            onChange={(e) => handleAddressData(e)}
                                            />
                                            <Button
                                            className="shadow-btn shadow-btn-width"
                                            variant="primary"
                                            onClick={saveEditUserAddress}
                                            >
                                            Save Address
                                            </Button>
                                        </div>
                                        </>
                                    )}
                                    </>
                                )}
                                </Col>
                        </Row>
                    </div>
                </div>
            </>) : (
            <>
                <div
                id="kt_content_container"
                className="d-flex flex-column-fluid align-items-start "
                >
                    <div
                    className={`${
                        isMobile
                        ? "px-5"
                        : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                    }`}
                    >
                    <div
                        className={`${
                        isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                        }`}
                    >
                        <div
                        className={`${
                            isMobile
                            ? ""
                            : "d-flex flex-center flex-column flex-column-fluid"
                        }`}
                        >
                        <div className="row">
                            <div className="col-12">
                            <div className="create-raffle-wallet-connet">
                                <img
                                className="reach-us-img"
                                alt=""
                                src={reach_us_img}
                                />
                                <div className="reach-us-to-whitelist">
                                Please connect your wallet.
                                </div>
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="wallet-button">
                                <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </>
        )}
        </>
    )
}