import React, {
  useEffect,
  useMemo,
  useState,
} from "react";


import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { useNavigate, useLocation, Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import { useCart } from './CartContext';
import "./header.css";
import logo from "../images/logo_v1.png";
import menu_icon from "../images/icons/menu.png";
import my_account from "../images/icons/account.png";
import left_arrow from "../images/icons/left.png";

export const Header = ({ publicKey }) => {
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new GlowWalletAdapter(),
      new MathWalletAdapter(),
    ],
    []
  );
  const endpoint = useMemo(() => clusterApiUrl("mainnet-beta"), []);
  const [toggled, setToggled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const match = pathname.match(/\/([^/]+)$/); // Extract the last path segment as the email
  const match2 = pathname.match(/\/([^/]+)\/[^/]+$/);
  const match3 = pathname.match(/\/([^/]+)\/([^/]+)\/([^/]+)$/);

  const email = match ? match[1] : undefined;
  const isHelioPaymentPage = match2 ? match2[1] : undefined;
  const isStorePage = match3 ? match3[1] : undefined;

  const { cartCount, initializeCartCount } = useCart();

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);


  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoCart = () => {
    console.log(email)
    if (email) {
      navigate('/shopping-cart/' + email);
    } else {
      navigate('/shopping-cart');
    }
  };

  const handleGoHome = () => {
    console.log(email)
    if (email) {
      navigate('/store-detail/5b77d31b-b649-434a-967e-690842c0d316/' + email);
    } else {
      navigate('/');
    }
  };


  
  const handleGoMyAccount = () => {
    console.log(email)
    if (email) {
      navigate('/my-orders/' + email);
    } else {
      navigate('/my-orders');
    }

  };


  useEffect(() => {
    async function data() {
      await getCart();
    }
    console.log("use effect running");
    data();
  }, [publicKey, email]);

  const getCart = async () => {
    console.log(publicKey);
    if (publicKey) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "getShoppingCart",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ walletId: publicKey.toString() }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          let quantity = 0;
          data['productData'].forEach(product => {
            console.log(product['productQuantity'])
            quantity = quantity + product['productQuantity'];
          });
          initializeCartCount(quantity)

        } else {
          console.log(response.status);

        }
      } catch (e) {
        console.log(e);

      }
    }
    if (email) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "getShoppingCart",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ emailId: email }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          let quantity = 0;
          data['productData'].forEach(product => {
            console.log(product['productQuantity'])
            quantity = quantity + product['productQuantity'];
          });
          initializeCartCount(quantity)
        } else {
          console.log(response.status);

        }
      } catch (e) {
        console.log(e);

      }
    }
  };



  return (
    <>
      <header className="header-area header-style-1 header-height-2">

        {/* <div className="header-top header-top-ptb-1 d-none d-lg-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-4">
                <div className="header-info">
                  <ul>
                    <li>
                      {" "}
                      <Link
                        to={{ pathname: "/my-account" }}
                        state={{ from: "my-account" }}
                      >
                        My Account
                      </Link>
                    </li>
                    <li>
                      <a href="shop-wishlist.html">Wishlist</a>
                    </li>
                    <li>
                      <Link
                        to={{ pathname: "/product-create" }}
                        state={{ from: "product-create" }}
                      >
                        Product Listing
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4">
                <div className="text-center">
                  <div id="news-flash" className="d-inline-block">
                    <ul>
                      <li className="color-white">Design Engine: Beta V1.0</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="header-info header-info-right">
                  <ul>
                    <li className="color-white">
                      Need help?{" "}
                      <span className="twitter-handle">TheVaultDAO</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="header-middle header-middle-ptb-1 d-lg-block d-sm-none">
          <div className="container-fluid">
            <div className="header-wrap">

              <div className="logo logo-width-1">

                <Link to={{ pathname: "/" }}
                  state={{ from: "header" }}>
                  <img src={logo} alt="logo" />
                </Link>
              </div>

              <div className="header-right">

                <div className="mobile-toggle" onClick={() => setToggled(!toggled)}>
                  <img width={50} src={menu_icon} alt="menu-icon" />
                </div>
                <div className="mobile-toggle" onClick={() => setToggled(!toggled)}>
                  <img width={50} src={menu_icon} alt="menu-icon" />
                </div>


                <div className="search-style-2"></div>
                <div className="header-action-right">
                  <div className="header-action-2">
                    <div className="header-info">
                      <ul>
                        <li>
                          {" "}
                          <Link
                            to={{ pathname: "/dashboard" }}
                            state={{ from: "home" }}
                          >

                            Dashboard
                          </Link>
                        </li>

                        <li>
                          <a href="#">About Us</a>
                        </li>
                        <li>
                          <a href="#">Contact Us</a>
                        </li>
                        <li>
                          <a href="#">Faq</a>
                        </li>
                      </ul>
                    </div>
                    <div className="header-cart-icon-container" onClick={handleGoCart}>
                    {cartCount > 0 && <span className="cart-amt-web">{cartCount}</span>}
                      <Icon.Bag className="header-cart-icon" onClick={handleGoCart} />
                      
                    </div>
                    <div className="header-action-icon-2">
                      <ConnectionProvider endpoint={endpoint}>
                        <WalletProvider wallets={wallets} autoConnect>
                          <WalletModalProvider>
                            <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                          </WalletModalProvider>
                        </WalletProvider>
                      </ConnectionProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom header-bottom-bg-color sticky-bar">
          <div className="container">
            <div className="header-wrap header-space-between position-relative">
              {/* <div className="logo logo-width-1 d-block d-lg-none">
                <a href="index.html">
                <img src={logo} alt="logo" />
                </a>
              </div> */}
              {isHelioPaymentPage != 'pay' &&
                <>
                  <div className="header-action-icon-2 d-block d-lg-none">
                    <div className="header-left">
                      <img src={left_arrow} className="back-arrow" alt="back-arrow" onClick={handleGoBack} />
                      {isStorePage != 'store-detail' &&
                        <Icon.House size={30} className="page-icons-2 ml-3" onClick={handleGoHome} />
                      }
                    </div>

                  </div>
                  <div className="header-action-right d-block d-lg-none">
                    <div className="header-action-2">
                      <>
                        <Icon.Bag size={30} className="page-icons-2" onClick={handleGoCart} />
                        {cartCount > 0 && <span className="cart-amt">{cartCount}</span>}
                      </>
                      <>
                        <img src={my_account} alt="my-account" onClick={handleGoMyAccount} />
                      </>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </header>
      <Sidebar
        id="sideBar"
        style={{ position: "absolute", minHeight: "100vh" }}
        onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="always"
      >
        <Menu style={{ padding: "0" }}>
          <MenuItem
            className="custom-menu-item"

            style={{ textAlign: "center" }}
          >
            <h5>Dashboard</h5>
          </MenuItem>
          <MenuItem
            className="custom-menu-item"
            style={{ textAlign: "center" }}
          >

            <h5>My Cart</h5>
          </MenuItem>
        </Menu>
      </Sidebar>
      <div className="mobile-header-active mobile-header-wrapper-style">

      </div>
    </>
  );
};

export default Header;
