import React from "react";
import Konva from "konva";
import {
  Stage,
  Layer,
  Image,
  Transformer,
  Rect,
  Text,
  Group,
} from "react-konva";
import useImage from "use-image";
import TshirtView from "./tshirtcolor";
import "./designer.css";
import close_img from "../assets/close.png";
import { ToastContainer, toast } from "react-toastify";
const dim_initial = {
  height: "",
  rotation: "",
  width: "",
  x: "",
  y: "",
};

export default function Designer({
  tshirt,
  elStage,
  tshirtOnChange,
  selected,
  setSelected,
  checkDeselect,
  dimention,
  setDimention,
  rectWidth,
  setRectWidth,
  rectHeight,
  setRectHeight,
  xaxis,
  setXaxis,
  yaxis,
  setYaxis
}) {
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [dimentionText, setDimentionText] = React.useState("");

  const dpi = 300; // set the DPI value
  const inputScaleValue = 6;
  const inchConvertValue = 96;

  let widthOfImage = (10 * inchConvertValue / inputScaleValue);
  let heightOfImage = (10 * inchConvertValue / inputScaleValue);
  console.log(widthOfImage, heightOfImage)
  React.useEffect(() => {
    setPageLoaded(true);
    // console.log('designer loaded')
  }, []);

  React.useEffect(() => {
    let width_as_in = Number(dimention.width).toFixed(1);
    let height_as_in = Number(dimention.height).toFixed(1);
    let t = width_as_in.toString() + " X " + height_as_in.toString() + " in";
    setDimentionText(t);
  }, [dimention]);

  return (
    <div className="w-full py-10 lg:py-0 flex min-h-0 lg:min-h-screen justify-center items-center">
      <ToastContainer pauseOnFocusLoss={false} />
      <div
        id="myDesign"
        ref={elStage}
        className="relative p-0 lg:p-10 flex justify-center items-center"
        style={{ position: "relative", height: "600px" }}
      >
        <Stage
          className="absolute"
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
          width={pageLoaded ? 200 : 0}
          height={pageLoaded ? 200 : 0}
          stroke="black"
          strokeWidth={2}
          zIndex={2}
        >
          <Layer  scaleX={0.5} scaleY={0.5}>
            <Rect
              x={xaxis}
              y={yaxis}
              width={rectWidth} // set the width in inches
              height={rectHeight} // set the height in inches
              stroke="#FFC300"
              strokeWidth={2}
              fill="#303030"
              dash={[5, 3]}
              scaleX={1 / 0.5}
              scaleY={1 / 0.5}
            />
          </Layer>

          <Layer>
            <DesignView
              isSelected={selected}
              data={tshirt}
              tshirt={
                tshirt.direction === "front"
                  ? tshirt.designs.front
                  : tshirt.designs.back
              }
              onSelect={() => {
                setSelected(true);
              }}
              onChange={tshirtOnChange}
              width={
                pageLoaded
                  ? (50 *
                      Math.round((40 * elStage.current.clientWidth) / 100)) /
                    100
                  : 0
              }
              dimention={dimention}
              setDimention={setDimention}
              setDimentionText={setDimentionText}
            />
          </Layer>
        </Stage>

        <TshirtView direction={tshirt.direction} color={tshirt.color} />
      </div>
    </div>
  );
}

const DesignView = ({
  isSelected,
  onSelect,
  tshirt,
  onChange,
  data,
  dimention,
  setDimention,
  setDimentionText,
}) => {
  const [image] = useImage(tshirt.preview, "Anonymous");
  let shapeRef = React.useRef();
  const trRef = React.useRef();
  const [isImageClosed, setsImageClosed] = React.useState(false);
  const removeImage = new window.Image();
  const customId = "image-quality-warning";
  const toastId = React.useRef(null);

  removeImage.src = close_img;

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.setNode(shapeRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  React.useEffect(() => {
    console.log(tshirt.preview);
  }, [onChange]);

  function onDragStart(e) {
    console.log(e);
  }

  const handleRemoveClick = () => {
    // remove the image from the layer and from the state

    shapeRef.current.destroy();
    setsImageClosed(true);
    shapeRef.current = new Konva.Shape();
    setDimention(dim_initial);
    // setImage(null);
  };

  const handleLoad = (event) => {
    const imgWidth = event.target.width;
    const imgHeight = event.target.height;
    // Do something with the image size
    console.log(`Image size: ${imgWidth * 0.0104} x ${imgHeight * 0.0104}`);
  };

  return (
    <React.Fragment>
      <Image
        zIndex={1}
        ref={shapeRef}
        isSelected={isSelected}
        image={image}
        draggable
        {...tshirt.positions}
        onClick={onSelect}
        onTap={onSelect}
        onDragStart={() => {
          onChange({
            ...data,
            designs: {
              ...data.designs,
              [data.direction]: {
                ...data.designs[data.direction],
                positions: {
                  ...data.designs[data.direction].positions,
                  isDragging: true,
                },
              },
            },
          });
        }}
        onDragEnd={(e) => {
          onDragStart(e.target.x());
          onChange({
            ...data,
            designs: {
              ...data.designs,
              [data.direction]: {
                ...data.designs[data.direction],
                positions: {
                  ...data.designs[data.direction].positions,
                  isDragging: false,
                  x: e.target.x(),
                  y: e.target.y(),
                },
              },
            },
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...data,
            designs: {
              ...data.designs,
              [data.direction]: {
                ...data.designs[data.direction],
                positions: {
                  ...data.designs[data.direction].positions,
                  x: node.x(),
                  y: node.y(),
                  // set minimal value
                  width: Math.max(5, node.width() * scaleX),
                  height: Math.max(node.height() * scaleY),
                },
              },
            },
          });
        }}
        onLoad={handleLoad}
      />
      {isSelected && !isImageClosed && (
        <Group>
          <Image
            image={removeImage}
            onClick={handleRemoveClick}
            x={shapeRef.current.x() - 10}
            y={shapeRef.current.y() - 20}
            width={20}
            height={20}
          />
        </Group>
      )}
      {isSelected && !isImageClosed && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          keepRatio={true}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
          boundBoxFunc={(oldBox, newBox) => {
            console.log(newBox);
            // limit resize

            setDimention(newBox);
            let width_as_in = (newBox.width / 300).toFixed(1);
            let height_as_in = (newBox.height / 300).toFixed(1);
            let t =
              width_as_in.toString() + " X " + height_as_in.toString() + " in";
            setDimentionText(t);
            if (width_as_in > 2 || height_as_in > 2) {
              if(! toast.isActive(toastId.current)) {
              toastId.current = toast.warn("High resolution design required", {
                position:"top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
                toastId: customId
              });
            }
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};
