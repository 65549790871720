import React, { useState } from "react";
import Swal from "sweetalert2";
import S3 from "react-aws-s3";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Buffer } from "buffer";


import "./create-store.css";
import step_icon from "../assets/Group 253.png";
import back_icon from "../assets/Group 83.svg";
import step_3_icon from "../assets/Group 261.png";
import step_2_icon from "../assets/Group 254.png";
import upload_icon from "../assets/icon_uploadone.png";
import defaultProfilePic from "../assets/Group 257.png";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

type StoreInfo = {
  storeName: string;
  storeUrl: string;
  storeDescription: string;
  storeBanner: string;
  storeProfile: string;
  website: string;
  discord: string;
  twitter: string;
  telegram: string;
  medium: string;
  youtube: string;
};

export const SignupForm = () => {
  const { publicKey, sendTransaction } = useWallet();
  const [currentStep, setCurrentStep] = useState(1);
  const [storeName, setStoreName] = useState("");
  const [storeUrl, setStoreUrl] = useState("");
  const [storeDescription, setStoreDescription] = useState("");
  const [storeBanner, setStoreBanner] = useState("");
  const [storeProfile, setStoreProfile] = useState("");
  const [website, setWebsite] = useState("");
  const [discord, setDiscord] = useState("");
  const [twitter, setTwitter] = useState("");
  const [telegram, setTelegram] = useState("");
  const [medium, setMedium] = useState("");
  const [youtube, setYoutube] = useState("");
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [profilePic, setProfilePic] = useState<string | null>(
    defaultProfilePic
  );
  const [errors, setErrors] = useState<StoreInfo>({
    storeName: "",
    storeUrl: "",
    storeDescription: "",
    storeBanner: "",
    storeProfile: "",
    website: "",
    discord: "",
    twitter: "",
    telegram: "",
    medium: "",
    youtube: "",
  });

  //File variables
  const [selectedFilName, setselectedFilName] = useState({});
  const [uploadedFile, setuploadedFile] = useState({});
  const [imageFile, setFile] = useState<any | null>({});
  const [fileNameFrReq, setFileNameFrReq] = useState<any | null>({});
  const [activeInputName, setActiveInputName] = useState<string | null>(null);
  const validateStep1 = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!storeName) {
      newErrors.storeName = "Store Name is required.";
      isValid = false;
    } else {
      newErrors.storeName = "";
    }

    if (!storeUrl) {
      newErrors.storeUrl = "Store URL is required.";
      isValid = false;
    } else {
      newErrors.storeUrl = "";
    }

    if (!storeDescription) {
      newErrors.storeDescription = "Store Description is required.";
      isValid = false;
    } else {
      newErrors.storeDescription = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateStep2 = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // if (!storeBanner) {
    //   newErrors.storeBanner = "Store Banner is required.";
    //   isValid = false;
    // } else {
    //   newErrors.storeBanner = "";
    // }

    // if (!storeProfile) {
    //   newErrors.storeProfile = "Store Profile is required.";
    //   isValid = false;
    // } else {
    //   newErrors.storeProfile = "";
    // }

    setErrors(newErrors);
    return isValid;
  };

  const validateStep3 = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Add validation for social links if required

    setErrors(newErrors);
    return isValid;
  };

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(currentStep);
    event.preventDefault();
    switch (currentStep) {
      case 1:
        if (validateStep1()) {
          setCurrentStep((prevStep) => prevStep + 1);
        }
        break;
      case 2:
        if (validateStep2()) {
          setCurrentStep((prevStep) => prevStep + 1);
        }
        break;
      case 3:
        if (validateStep3()) {
          setCurrentStep((prevStep) => prevStep + 1);
          uploadImage(imageFile, fileNameFrReq, uploadedFile);
        }
        break;
      default:
        break;
    }
  };

  const handleInputFocus = (field: keyof StoreInfo) => {
    // Clear the error message when the input field is focused
    const newErrors: StoreInfo = { ...errors }; // Create a new object of the same type as 'errors'
    newErrors[field] = ""; // Clear the specific field error
    setErrors(newErrors);
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      let fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileExtension.toLowerCase() === "gif" ||
        fileExtension.toLowerCase() === "jpg" ||
        fileExtension.toLowerCase() === "jpeg" ||
        fileExtension.toLowerCase() === "png"
      ) {
        let regName = Math.random() + "_" + event.target.files?.[0].name;
        setuploadedFile({
          ...uploadedFile,
          [event.target.name]: event.target.files?.[0],
        });
        setselectedFilName({
          ...selectedFilName,
          [event.target.name]: event.target.files?.[0].name,
        });
        setFile({
          ...imageFile,
          [event.target.name]: event.target.files?.[0].name,
        });
        setFileNameFrReq({ ...fileNameFrReq, [event.target.name]: regName });

        updateThumbnail(file);
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!activeInputName) return;

    const file = event.dataTransfer.files[0];
    if (file) {
      let fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileExtension.toLowerCase() === "gif" ||
        fileExtension.toLowerCase() === "jpg" ||
        fileExtension.toLowerCase() === "jpeg" ||
        fileExtension.toLowerCase() === "png"
      ) {
        let regName = Math.random() + "_" + file.name;
        setuploadedFile({
          ...uploadedFile,
          [activeInputName]: file,
        });
        setselectedFilName({
          ...selectedFilName,
          [activeInputName]: file.name,
        });
        setFile({
          ...imageFile,
          [activeInputName]: file.name,
        });
        setFileNameFrReq({ ...fileNameFrReq, [activeInputName]: regName });

        updateThumbnail(file);
      }
    }
  };
  const handleZoneClick = (name: string) => {
    // Trigger the file input click when the drop zone area is clicked
    setActiveInputName(name);
    // document.querySelector(".drop-zone__input").click();
    const inputElement = document.querySelector(
      ".drop-zone__input"
    ) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const updateThumbnail = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const dataURL = reader.result as string;
      setThumbnail(dataURL);
    };
  };

  const handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      let fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileExtension.toLowerCase() === "gif" ||
        fileExtension.toLowerCase() === "jpg" ||
        fileExtension.toLowerCase() === "jpeg" ||
        fileExtension.toLowerCase() === "png"
      ) {
        let regName = Math.random() + "_" + event.target.files?.[0].name;
        setuploadedFile({
          ...uploadedFile,
          [event.target.name]: event.target.files?.[0],
        });
        setselectedFilName({
          ...selectedFilName,
          [event.target.name]: event.target.files?.[0].name,
        });
        setFile({
          ...imageFile,
          [event.target.name]: event.target.files?.[0].name,
        });
        setFileNameFrReq({ ...fileNameFrReq, [event.target.name]: regName });

        previewFile(file);
      }
    }
  };

  const previewFile = (file: File) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const dataURL = reader.result as string;

        setProfilePic(dataURL);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleProfilePicClick = () => {
    // Trigger the file input click when the profile picture area is clicked
    console.log("profile click")
    const inputElement = document.getElementById(
      "profile-image-upload"
    ) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  type ImageFile = {
    [key: string]: File;
  };

  // const uploadImage = (
  //   imageFile: ImageFile,
  //   fileNameFrReq: Record<string, string>,
  //   uploadedFile: Record<string, File>
  // ) => {
  //   let img_keys = Object.keys(imageFile);
  //   console.log("imageFile", imageFile);
  //   console.log("fileNameFrReq", fileNameFrReq);
  //   console.log("uploadedFile", uploadedFile);
  //   for (let i = 0; i < img_keys.length; i++) {
  //     if (imageFile[img_keys[i]]) {
  //       const config = {
  //         bucketName: process.env.REACT_APP_BUCKET_NAME,
  //         region: process.env.REACT_APP_REGION,
  //         accessKeyId: process.env.REACT_APP_ACCESS_ID,
  //         secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  //         s3Url: process.env.REACT_APP_S3URL,
  //       };

  //       const ReactS3Client = new S3(config);
  //       ReactS3Client.uploadFile(
  //         uploadedFile[img_keys[i]],
  //         fileNameFrReq[img_keys[i]]
  //       ).then((data: any) => {
  //         if (data.status === 204) {
  //           console.log("success");
  //           if (i == img_keys.length - 1) {
  //             createProduct(fileNameFrReq);
  //           }
  //         } else {
  //           console.log("fail");
  //           Swal.fire({
  //             text: "Image not uploaded. please try again",
  //             confirmButtonText: "Close",
  //           });
  //         }
  //       });
  //     }
  //   }
  // };
 
  const uploadImage = async (
    imageFile: ImageFile,
    fileNameFrReq: Record<string, string>,
    uploadedFile: Record<string, File>
  ) => {
    const img_keys = Object.keys(imageFile);
  
    for (let i = 0; i < img_keys.length; i++) {
      const key = img_keys[i];
      const file = uploadedFile[key];
      const fileName = fileNameFrReq[key];
     console.log(fileNameFrReq[key])
      if (imageFile[key] && file) {
        const config = {
          bucketName: process.env.REACT_APP_BUCKET_NAME,
          region: process.env.REACT_APP_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_ID,
          secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
          s3Url: process.env.REACT_APP_S3URL,
        };
  
        const ReactS3Client = new S3(config);
  
        try {
          const data = await ReactS3Client.uploadFile(file, fileName);
          if (data.status === 204) {
            console.log("success");
            if (i === img_keys.length - 1) {
              createProduct(fileNameFrReq);
            }
          } else {
            console.log("fail");
            Swal.fire({
              text: "Image not uploaded. Please try again",
              confirmButtonText: "Close",
            });
          }
        } catch (error) {
          console.error("Error uploading image:", error);
          Swal.fire({
            text: "An error occurred while uploading the image. Please try again.",
            confirmButtonText: "Close",
          });
        }
      }
    }
  };
  

  type ProductData = {
    walletID: string;
    storeBanner: string;
    storeAvatar: string;
    storeName: string;
    storeUrl: string;
    storeDescription: string;
    socialLink:object
  };

  const createProduct = async (fileNameFrReq: any) => {
    if (!publicKey) return;
    try {
      let product_data: ProductData = {
        walletID: publicKey.toString(),
        storeBanner: fileNameFrReq["storeBanner"],
        // storeAvatar: fileNameFrReq["storeAvatar"],
        storeAvatar: "dd",
        storeName: storeName,
        storeUrl: storeUrl,
        storeDescription: storeDescription,
        socialLink:{
          'twitter':twitter,
          'website':website,
          'discord':discord,
          'telegram':telegram,
          'medium':medium,
          'youtube':youtube,
        }
        // ... add other properties
      };

      // product_data['storeName'] =
      // product_data['storeName'] =
      console.log("product data");
      console.log(product_data);
      let img_file_keys = Object.keys(fileNameFrReq);
      console.log(img_file_keys);

      const response = await fetch(process.env.REACT_APP_API_URL + "create_store", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(product_data),
      });
      if (response.status === 200) {
        let product_resp = await response.json();
        console.log(product_resp);
        var r = window.confirm("Product Saved Successfully!");
        if (r == true) {
          window.location.reload();
        }
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  return (
    <main className="main">
      <section className="container">
        <div className="stepper-container">
          <div className="heading-inside">
            <div className="first_h">Create Store</div>
            <div className="second_h">
              Sell custom t-shirts and hoodies easily with our platform. We
              handle production and shipping, so you can focus on designing.
              Your customers can personalize their items for a unique touch.
              Start creating today!
            </div>
          </div>
          <div className="stepper-progress-bar">
            <div className="step">
              <div className={`bullet ${currentStep >= 1 ? "active" : ""}`}>
                <div className="step-inner">
                  <span>
                    <img
                      alt=""
                      src={currentStep > 1 ? step_2_icon : step_icon}
                    />
                  </span>
                  <span className="step-inner-label">Basic Information</span>
                </div>
              </div>
              <div
                className={`check fas fa-check ${
                  currentStep >= 1 ? "active" : ""
                }`}
              ></div>
            </div>
            <div className="step">
              <div className={`bullet ${currentStep >= 2 ? "active" : ""}`}>
                <div className="step-inner">
                  <span>
                    <img
                      alt=""
                      src={currentStep > 2 ? step_2_icon : step_icon}
                    />
                  </span>
                  <span className="step-inner-label">Store Design</span>
                </div>
              </div>
              <div
                className={`check fas fa-check ${
                  currentStep >= 2 ? "active" : ""
                }`}
              ></div>
            </div>
            <div className="step">
              <div className={`bullet ${currentStep >= 3 ? "active" : ""}`}>
                <div className="step-inner">
                  <span>
                    <img
                      alt=""
                      src={currentStep > 3 ? step_2_icon : step_icon}
                    />
                  </span>
                  <span className="step-inner-label">Social links</span>
                </div>
              </div>
              <div
                className={`check fas fa-check ${
                  currentStep >= 3 ? "active" : ""
                }`}
              ></div>
            </div>
          </div>
          <div className="form-outer">
            <form>
              {currentStep === 1 && (
                <div
                  className={`page ${currentStep === 1 ? "slide-page" : ""}`}
                >
                  <div className="title">Basic Information</div>
                  <div className="field">
                    <div className="label">Store Name</div>
                    <input
                      value={storeName}
                      onChange={(e) => setStoreName(e.target.value)}
                      onFocus={() => handleInputFocus("storeName")}
                      type="text"
                    />
                    {errors.storeName && (
                      <div className="error">{errors.storeName}</div>
                    )}
                  </div>
                  <div className="field">
                    <div className="label">Store Url</div>
                    <input
                      type="text"
                      value={storeUrl}
                      onChange={(e) => setStoreUrl(e.target.value)}
                      onFocus={() => handleInputFocus("storeUrl")}
                    />
                    {errors.storeUrl && (
                      <div className="error">{errors.storeUrl}</div>
                    )}
                  </div>
                  <div className="field">
                    <div className="label">Store Description</div>
                    <textarea
                      value={storeDescription}
                      onChange={(e) => setStoreDescription(e.target.value)}
                      onFocus={() => handleInputFocus("storeDescription")}
                    ></textarea>
                    {errors.storeDescription && (
                      <div className="error">{errors.storeDescription}</div>
                    )}
                  </div>
                  <div className="step-button-container">
                    {currentStep < 4 && (
                      <button
                        className="btn btn-md rounded font-sm hover-up create-product-btn firstNext next"
                        onClick={handleNext}
                      >
                        Save & Next
                      </button>
                    )}
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div className={`page ${currentStep === 2 ? "" : "hidden"}`}>
                  <div className="title">Store Design</div>
                  <div className="field">
                    <div className="label">Store banner</div>
                    <div
                      className={`drop-zone ${
                        thumbnail ? "has-thumbnail" : ""
                      }`}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={handleDrop}
                      onClick={() => handleZoneClick("myFile")} // Add the click event handler here
                    >
                      {!thumbnail && (
                        <>
                          <div className="drop-zone__prompt1">
                            Click to upload banner{" "}
                            <img src={upload_icon} alt="upload-icon" />
                          </div>
                          <br />
                          <div className="drop-zone__prompt">
                            or, drag and drop a file here
                          </div>
                        </>
                      )}
                      {thumbnail && (
                        <div
                          className="drop-zone__thumb"
                          style={{ backgroundImage: `url('${thumbnail}')` }}
                        />
                      )}
                      <input
                        type="file"
                        name="storeBanner"
                        className="drop-zone__input"
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <input
                      type="text"
                      value={storeBanner}
                      onChange={(e) => setStoreBanner(e.target.value)}
                      onFocus={() => handleInputFocus("storeBanner")}
                    /> */}
                  </div>

                  <div className="field">
                    <div className="label">Store profile</div>
                    <div
                      className="profile-pic"
                      onClick={handleProfilePicClick}
                    >
                      {profilePic !== null ? (
                        <img alt="User Pic" src={profilePic} height="200" />
                      ) : (
                        <div className="profile-pic__placeholder">
                          <span>No profile picture</span>
                        </div>
                      )}
                      <div className="profile-pic__overlay">
                        <button className="profile-pic__button">
                          Change Image
                        </button>
                      </div>
                      <input
                        type="file"
                        name="storeAvatar"
                        id="profile-image-upload"
                        className="drop-zone__input"
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <textarea
                      value={storeProfile}
                      onChange={(e) => setStoreProfile(e.target.value)}
                      onFocus={() => handleInputFocus("storeProfile")}
                    ></textarea> */}
                  </div>
                  <div className="step-button-container">
                    {currentStep < 4 && (
                      <>
                        <button
                          className="btn btn-md rounded font-sm hover-up back-btn firstNext next"
                          onClick={handlePrevious}
                        >
                          <span className="icon"></span>
                          Back
                        </button>
                        <button
                          className="btn btn-md rounded font-sm hover-up create-product-btn firstNext next"
                          onClick={handleNext}
                        >
                          Save & Next
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
              {currentStep === 3 && (
                <div className={`page ${currentStep === 3 ? "" : "hidden"}`}>
                  <div className="title">Social links</div>
                  <div className="field">
                    <div className="label">Website</div>
                    <input
                      type="text"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      onFocus={() => handleInputFocus("website")}
                    />
                    {errors.website && (
                      <div className="error">{errors.website}</div>
                    )}
                  </div>
                  <div className="field">
                    <div className="label">Discord</div>
                    <input
                      type="text"
                      value={discord}
                      onChange={(e) => setDiscord(e.target.value)}
                      onFocus={() => handleInputFocus("discord")}
                    />
                    {errors.discord && (
                      <div className="error">{errors.discord}</div>
                    )}
                  </div>
                  <div className="field">
                    <div className="label">Twitter</div>
                    <input
                      type="text"
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                      onFocus={() => handleInputFocus("twitter")}
                    />
                    {errors.twitter && (
                      <div className="error">{errors.twitter}</div>
                    )}
                  </div>
                  <div className="field">
                    <div className="label">Telegram</div>
                    <input
                      type="text"
                      value={telegram}
                      onChange={(e) => setTelegram(e.target.value)}
                      onFocus={() => handleInputFocus("telegram")}
                    />
                    {errors.telegram && (
                      <div className="error">{errors.telegram}</div>
                    )}
                  </div>
                  <div className="field">
                    <div className="label">Medium</div>
                    <input
                      type="text"
                      value={medium}
                      onChange={(e) => setMedium(e.target.value)}
                      onFocus={() => handleInputFocus("medium")}
                    />
                    {errors.medium && (
                      <div className="error">{errors.medium}</div>
                    )}
                  </div>
                  <div className="field">
                    <div className="label">Youtube</div>
                    <input
                      type="text"
                      value={youtube}
                      onChange={(e) => setYoutube(e.target.value)}
                      onFocus={() => handleInputFocus("youtube")}
                    />
                    {errors.youtube && (
                      <div className="error">{errors.youtube}</div>
                    )}
                  </div>
                  <div className="step-button-container">
                    {currentStep < 4 && (
                      <>
                        <button
                          className="btn btn-md rounded font-sm hover-up back-btn firstNext next"
                          onClick={handlePrevious}
                        >
                          <span className="icon"></span>
                          Back
                        </button>
                        <button
                          className="btn btn-md rounded font-sm hover-up create-product-btn firstNext next"
                          onClick={handleNext}
                        >
                          Save
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};
