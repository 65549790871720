import React, {
    useEffect,
    useState,
    CSSProperties
} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Modal, Row, Col, Card, Button, Form, Stack } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useCart } from '../components/CartContext';
import reach_us_img from "../images/contact-us.png";
import checkoutImg from "../images/checkout-img.png";
import cartArrow from "../images/icons/cart-arrow.png";
import deleteIcon from "../images/icons/delete-icon.png";
import quantityminus from "../images/icons/quantityminus.png";
import quantityplus from "../images/icons/quantityplus.png";


import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
    WalletDisconnectButton,
    WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shopping-cart.css";


const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "#fff",
};

export const ShoppingCart = ({ isApp, isAppEmailAddress, publicKey }) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    const [allProduct, setAllProduct] = useState([]);
    const [productQuantity, setProductQuantity] = useState(1);
    const [subTotal, setSubTotal] = useState(0.0);
    const [tax, setTax] = useState(18);
    const [taxValue, setTaxValue] = useState(0);
    const [userId, SetUserId] = useState('');
    const [orderPaymentCurrency, setOrderPaymentCurrency] = useState('');
    const [isMultiplePaymentCurrency, setIsMultiplePaymentCurrency] = useState(false);



    const { email } = useParams();
    let [loading, setLoading] = useState(true);

    const { addToCart, removeFromCart, initializeCartCount } = useCart();

    const increaseProductQuantity = async (cartID, productQuantityValue, productSelectedPayment, price) => {
        setProductQuantity(productQuantity + 1);
        productQuantityValue = productQuantityValue + 1;
        let sol_price;
        if(productSelectedPayment == 'SOL'){
            sol_price = await getSolPrice()
        }
        
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "updateShoppingCart",
                {
                    method: "POST",
                    mode: "cors",
                    headers: new Headers({ "content-type": "application/json" }),
                    body: JSON.stringify({ id: cartID, quantity: productQuantityValue, currency: productSelectedPayment, price:price, solPrice:sol_price }),
                }
            );
            if (response.status === 200) {
                getProductsByUserID();
                addToCart(1)
                // window. location. reload();
            } else {
                console.log(response.status);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const decreaseProductQuantity = async (cartID, productQuantityValue, productSelectedPayment, price) => {
        console.log(productQuantityValue)
        if (productQuantityValue > 1) {
            setProductQuantity(productQuantity - 1)
            productQuantityValue = productQuantityValue - 1;
            let sol_price;
            if(productSelectedPayment == 'SOL'){
                sol_price = await getSolPrice()
            }
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "updateShoppingCart",
                    {
                        method: "POST",
                        mode: "cors",
                        headers: new Headers({ "content-type": "application/json" }),
                        body: JSON.stringify({ id: cartID, quantity: productQuantityValue,currency: productSelectedPayment, price:price, solPrice:sol_price }),
                    }
                );
                if (response.status === 200) {
                    getProductsByUserID();
                    removeFromCart(1)
                    // window. location. reload();
                } else {
                    console.log(response.status);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
    const RemoveCartProduct = async (cartID) => {
        const response = await fetch(process.env.REACT_APP_API_URL + "deleteCart", {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ id: cartID }),
        });
        if (response.status === 200) {
            getProductsByUserID();
        } else {
            console.log();
        }
    }
    function getTax() {
        var taxCal = (tax * subTotal) / 100;
        setTaxValue(taxCal);
    }

    const getProductsByUserID = async () => {
        console.log(publicKey);
        if (publicKey) {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "getShoppingCart",
                    {
                        method: "POST",
                        mode: "cors",
                        headers: new Headers({ "content-type": "application/json" }),
                        body: JSON.stringify({ walletId: publicKey.toString() }),
                    }
                );
                if (response.status === 200) {
                    const data = await response.json();
                    console.log(data);
                    setAllProduct(data['productData']);
                    setSubTotal(data['cartSubTotal']);
                    let quantity = 0;
                    let payment_currency = ''
                    let is_multiple_payment_currency = false
                    data['productData'].forEach(product => {
                        if (payment_currency == '') {
                            payment_currency = product['productSelectedPayment']
                        } else if (payment_currency != product['productSelectedPayment']) {
                            is_multiple_payment_currency = true
                        }
                        console.log(product['productQuantity'])
                        quantity = quantity + product['productQuantity'];
                    });
                    console.log(is_multiple_payment_currency)
                    setOrderPaymentCurrency(payment_currency)
                    setIsMultiplePaymentCurrency(is_multiple_payment_currency)
                    initializeCartCount(quantity)
                    setLoading(false)
                } else {
                    console.log(response.status);
                    setLoading(false)
                }
            } catch (e) {
                console.log(e);
                setLoading(false)
            }
        }
        if (email) {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "getShoppingCart",
                    {
                        method: "POST",
                        mode: "cors",
                        headers: new Headers({ "content-type": "application/json" }),
                        body: JSON.stringify({ emailId: email }),
                    }
                );
                if (response.status === 200) {
                    const data = await response.json();
                    console.log(data);
                    setAllProduct(data['productData']);
                    // setSubTotal(data['cartSubTotal']);
                    let total = 0;
                    let quantity = 0;
                    let payment_currency = ''
                    let is_multiple_payment_currency = false
                    data['productData'].forEach(product => {
                        if (payment_currency == '') {
                            payment_currency = product['productSelectedPayment']
                        } else if (payment_currency != product['productSelectedPayment']) {
                            is_multiple_payment_currency = true
                        }
                        console.log(product['productQuantity'])
                        quantity = quantity + product['productQuantity'];
                        total = total + Number(product['cartPrice'])
                    });
                    setSubTotal(total)
                    console.log(is_multiple_payment_currency)
                    setOrderPaymentCurrency(payment_currency)
                    setIsMultiplePaymentCurrency(is_multiple_payment_currency)
                    initializeCartCount(quantity)
                    setLoading(false)
                } else {
                    console.log(response.status);
                    setLoading(false)
                }
            } catch (e) {
                console.log(e);
                setLoading(false)
            }
        }
    };
    const redirect = () => {
        if (email) {
            navigate("/cart-check-out/" + email);
        } else {
            navigate("/cart-check-out");
        }

    }

    const handleGoHome = () => {
        if (email) {
            navigate("/" + email);
        } else {
            navigate("/");
        }

    };

    // useEffect(() => {
    //     // var url_path = window.location.pathname;
    //     // console.log(url_path);
    //     // var product_id = url_path.split("/")[2];
    //     // console.log(product_id);
    //     SetUserId("93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi");

    //   }, [navigate]);
    useEffect(() => {
        async function taxdata() {
            await getTax();
        }
        taxdata();
    }, [navigate, userId, subTotal]);
    useEffect(() => {
        async function data() {

            if (publicKey) {
                SetUserId(publicKey.toString());
            }
            if (email) {
                SetUserId(email);
            }
            await getProductsByUserID();
        }
        console.log("use effect running");
        data();
    }, [navigate, publicKey, email]);

    const handleRadioChange = (event) => {
        setOrderPaymentCurrency(event.target.value);
    };

    const changePaymentCurrency = async () => {
        console.log(allProduct)
        console.log(orderPaymentCurrency)
        let sol_price = await getSolPrice()
        if(sol_price){
            console.log(sol_price)
        
            let payload = {
                'cartData' : allProduct,
                'orderPaymentCurrency':orderPaymentCurrency,
                'solPrice':sol_price
            }
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "updatePaymentCurrency",
                    {
                        method: "POST",
                        mode: "cors",
                        headers: new Headers({ "content-type": "application/json" }),
                        body: JSON.stringify(payload),
                    }
                );
                if (response.status === 200) {
                    const data = await response.json();
                    console.log(data);               
                    getProductsByUserID();
                } else {
                    console.log(response.status);               
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    const getSolPrice = async () => {

        try {
            const response = await fetch("https://api.binance.com/api/v1/ticker/price?symbol=SOLUSDT",
                {
                    method: "GET",
                    mode: "cors",
                }
            );
            if (response.status === 200) {
                const data = await response.json();
                console.log(data);
                return data['price']               

            } else {
                console.log(response.status);
                return false
            }
        } catch (e) {
            console.log(e);
            return false
        }
    }

    return (
        <>
            <ClipLoader
                loading={loading}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="loader"
            />
            {!loading && (
                <>
                    {true ? (
                        <>
                            <div className="shopping-cart-main">
                                <div className="cart-head">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="cart-heading">
                                                <h1>Shopping Cart</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="cart-content">
                                    <Row>
                                        <Col sm={7}>
                                            <div>
                                                {allProduct.length > 0 && <div className="table-heading">Product Details</div>}
                                                <table className="cart-table desktop-cart-table">
                                                    <thead className="cart-table-head">
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Size</th>
                                                            <th>Quantity</th>
                                                            <th>Total Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="cart-table-body">
                                                        {allProduct.map((product, productIndex) => (
                                                            <tr key={productIndex}>
                                                                <td>
                                                                    <Row>
                                                                        <Col sm={3}>
                                                                            <img src={product['imagesFront']} alt="product-img"/>
                                                                        </Col>
                                                                        <Col sm={9}>
                                                                            <Link
                                                                                to={`/store-product-detail/${product["id"]}`}
                                                                            >
                                                                                <h3>{product['productName']}</h3>
                                                                            </Link>

                                                                            <h6>Colour: {product['selectedColor']}</h6>
                                                                            <h4 className="product-remove" id={product['cartId']} onClick={() => RemoveCartProduct(product['cartId'])}>Remove</h4>
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                                <td className="table-productSelectedSize">
                                                                    {product['productSelectedSize']}
                                                                    {/* <Form.Control as="select" className="form-input" size="sm" type="text" placeholder="Select Country *" >
                                                          <option selected value="S">S</option>
                                                          <option value="M">M</option>
                                                          <option value="L">L</option>
                                                      </Form.Control> */}
                                                                </td>
                                                                <td>
                                                                    <div className="quantity">
                                                                        <Icon.DashSquare id={product['cartId']} size={30} className="page-icons" onClick={() => decreaseProductQuantity(product['cartId'], product['productQuantity'], product['productSelectedPayment'], product['price'])} />

                                                                        <h1>{product['productQuantity']}</h1>

                                                                        <Icon.PlusSquare id={product['cartId']} size={30} className="page-icons" onClick={() => increaseProductQuantity(product['cartId'], product['productQuantity'], product['productSelectedPayment'], product['price'])} />
                                                                    </div>
                                                                </td>
                                                                <td className="table-row-total">{Number(product['cartPrice']).toFixed(4)} {product['productSelectedPayment']} </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <table className="cart-table mobile-cart-table">
                                                    {/* <thead className="cart-table-head">
                                          <tr>
                                              <th>Product</th>
                                              <th>Size</th>
                                              <th>Quantity</th>
                                              <th>Total Price</th>
                                          </tr>
                                      </thead> */}
                                                    <tbody className="cart-table-body">
                                                        {allProduct.map((product, productIndex) => (
                                                            <tr key={productIndex}>
                                                                <td>
                                                                <img src={product['imagesFront']} alt="product-img"/>
                                                                </td>
                                                                <td className="table-product-detail">
                                                                    <h3>{product['productName']}</h3>
                                                                    <h5>Size: {product['productSelectedSize']}</h5>
                                                                    <div className="quantity">
                                                                        <Icon.DashSquare id={product['cartId']} size={20} className="page-icons" onClick={() => decreaseProductQuantity(product['cartId'], product['productQuantity'], product['productSelectedPayment'], product['price'])} />

                                                                        <h1>{product['productQuantity']}</h1>
                                                                        <Icon.PlusSquare id={product['cartId']} size={20} className="page-icons" onClick={() => increaseProductQuantity(product['cartId'], product['productQuantity'], product['productSelectedPayment'], product['price'])} />

                                                                    </div>
                                                                </td>
                                                                <td className="table-row-total">
                                                                    {Number(product['cartPrice']).toFixed(4)} {product['productSelectedPayment']}
                                                                    <h4 className="product-remove" id={product['cartId']} onClick={() => RemoveCartProduct(product['cartId'])}><img src={deleteIcon} /></h4>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                        {allProduct.length > 0 && !isMultiplePaymentCurrency &&
                                            <Col sm={5}>
                                                <div>
                                                    <Card className="shopping-card">
                                                        <Card.Body className="shopping-card-body">
                                                            <div className="card-heading">
                                                                <h1>Order Summary</h1>
                                                            </div>
                                                            <div className="cart-details">
                                                                <h3>Subtotal <span>{subTotal.toFixed(4)} {orderPaymentCurrency}</span></h3>
                                                                <h3>Shipping <span>Calculated at next step</span></h3>
                                                                <h3>Taxes (estimated) <span>{taxValue.toFixed(2)} {orderPaymentCurrency}</span></h3>
                                                            </div>
                                                            <div className="cart-total">
                                                                <h1>Total <span>{(subTotal + taxValue).toFixed(2)} {orderPaymentCurrency}</span></h1>
                                                            </div>
                                                            <div className="place-order desktop-place-order">
                                                                <Button className="shadow-btn shadow-btn-width" variant="primary" onClick={redirect}>CHECK OUT <Icon.ArrowRight size={36} className="btn-icons" /></Button>
                                                            </div>
                                                            <div className="place-order mobile-place-order">
                                                                <Button className="shadow-btn shadow-btn-width" variant="primary" onClick={redirect}>CHECK OUT <Icon.ArrowRight size={25} className="btn-icons" /></Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }
                                        {allProduct.length == 0 &&
                                            <Col sm={5}>
                                                <div>
                                                    <Card className="shopping-card">
                                                        <Card.Body className="shopping-card-body">
                                                            <div className="card-heading">
                                                                <h1>Your Cart is empty.</h1>
                                                            </div>
                                                            <h3 onClick={handleGoHome}>Continue shopping.</h3>


                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }
                                        {isMultiplePaymentCurrency &&
                                            <Col sm={5}>
                                                <div>
                                                    <Card className="shopping-card">
                                                        <Card.Body className="shopping-card-body">
                                                            <div>
                                                                <div className="currency-chooser-label">Your Cart has multiple payment currency.Please choose any one for payment.</div>
                                                                <div className="currency-chooser">
                                                                    <label><input type="radio" value='SOL' className="input-currency sol" checked={orderPaymentCurrency === 'SOL'}
                                                                        onChange={handleRadioChange} /> SOL</label>
                                                                    <label><input type="radio" value='USDC' className="input-currency usdc" checked={orderPaymentCurrency === 'USDC'}
                                                                        onChange={handleRadioChange} /> USDC</label>
                                                                </div>
                                                                <div className="text-center pt-3">
                                                                    <Button className="mx-auto" onClick={changePaymentCurrency}>Confirm</Button>
                                                                </div>
                                                            </div>



                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </div>
                        </>) : (
                        <>
                            <div
                                id="kt_content_container"
                                className="d-flex flex-column-fluid align-items-start "
                            >
                                <div
                                    className={`${isMobile
                                        ? "px-5"
                                        : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                                        }`}
                                >
                                    <div
                                        className={`${isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                                            }`}
                                    >
                                        <div
                                            className={`${isMobile
                                                ? ""
                                                : "d-flex flex-center flex-column flex-column-fluid"
                                                }`}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="create-raffle-wallet-connet">
                                                        <img
                                                            className="reach-us-img"
                                                            alt=""
                                                            src={reach_us_img}
                                                        />
                                                        <div className="reach-us-to-whitelist">
                                                            Please connect your wallet.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="wallet-button">
                                                        <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}