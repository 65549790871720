import React, { useEffect, useCallback, useState, useReducer } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";

import "./home.css";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import banner1 from "../images/Banner.png";
import cat_cars from "../images/products/Cat-Cars.jpeg";
import cat_electronics from "../images/products/Cat-Electronics.jpeg";
import cat_fine_art from "../images/products/Cat-Fine-Art.jpeg";
import cat_furnitures from "../images/products/Cat-Furnitures.jpeg";
import cat_real_estate from "../images/products/Cat-Real-Estate.jpeg";
import cat_tools from "../images/products/Cat-Tools.jpeg";



export const Home = () => {

  // Access the email parameter from the URL
  const { email } = useParams();

  const { connection } = useConnection();
  const [isPreLoading, setIsPreLoading] = useState(false);
  const { wallet, publicKey, sendTransaction } = useWallet();
  const [allProducts, setAllProducts] = useState([]);
  const [allstores, setAllStores] = useState([]);
  const navigate = useNavigate();
  const validator = new PublicKey(
    "8kQXBqHUa5Jaj1khGpdz73nYRCLtUtFviFnVui1RohVJ"
  );
  const initiator = new PublicKey(
    "JeJNxLLBLY7qhkFWEJbyETCf1nL2R85u91oQyxxqxgh"
  );
  const address = new PublicKey("wEEdop2S1t7TLLeikEJ3awAWq9ty6wmf9v8wiS7beBB");
  const escrowVaultWord = "mainnetforgewithtest";
  const amount = 1000000000; // Adjust for SPL decimals
  const deposit = 1000000000; // Adjust for SPL decimals

  useEffect(() => {
    async function data() {
      setIsPreLoading(true);
      await getStores();
    }
    data();
  }, [navigate]);

  const getAllProducts = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "get_allproduct",
        { mode: "cors" }
      );
      if (response.status === 200) {
        let data = await response.json();
        setAllProducts(data["product_details"]);
        setIsPreLoading(false);
      } else {
        setIsPreLoading(false);
      }
    } catch (e) {
      setIsPreLoading(false);
      console.log(e);
      return;
    }
  };





  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
  };

  var hero_settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    loop: true,
    dots: true,
    arrows: true,
    // prevArrow:
    //   '<span className="slider-btn slider-prev"><i className="fi-rs-angle-left"></i></span>',
    // nextArrow:
    //   '<span className="slider-btn slider-next"><i className="fi-rs-angle-right"></i></span>',
    // appendArrows: ".hero-slider-1-arrow",
    autoplay: true,
  };

  var product_carausel_settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    loop: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  const getStores = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "get_allStores",
        {
          method: "GET",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log('response' + data['stores']);
        setAllStores(data['stores']);
        setIsPreLoading(false);
      } else {
        setIsPreLoading(false);
      }
    } catch (e) {
      setIsPreLoading(false);
     }
  };

  
  useEffect(() => {
    console.log("------- Email Received --------");
  
   console.log(email)
  }, [email]);

  return (
    <>
      <main className="main">
        <section className="home-slider position-relative mb-30">
          <div className="container">
            <div className="home-slide-cover mt-30">
              <div className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
                <Slider {...hero_settings}>
                  <img src={banner1} alt="banner1" />
                </Slider>
              </div>
              {/* <div className="slider-arrow hero-slider-1-arrow"></div> */}
            </div>
          </div>
        </section>

        <section className="product-tabs section-padding position-relative">
          <div className="container">
            <div className="section-title style-2">
              <h3>Popular Stores</h3>
            </div>
            <div className="row product-grid-4">
              {!isPreLoading && (
                <>
                  {allstores.map((store, index) => (
                    <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                      <div className="product-cart-wrap mb-30">
                        <div className="product-img-action-wrap">
                          <div className="product-img product-img-zoom">
                            
                          </div>
                          <div className="product-action-1">
                            <a
                              aria-label="Add To Wishlist"
                              className="action-btn"
                              href="shop-wishlist.html"
                            >
                              <i className="fi-rs-heart"></i>
                            </a>
                            <a
                              aria-label="Compare"
                              className="action-btn"
                              href="shop-compare.html"
                            >
                              <i className="fi-rs-shuffle"></i>
                            </a>
                            <a
                              aria-label="Quick view"
                              className="action-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#quickViewModal"
                            >
                              <i className="fi-rs-eye"></i>
                            </a>
                          </div>

                        </div>
                        <div className="product-content-wrap">
                         
                          <h2>
                          <Link to={ email ? `/store-detail/${store['id']}/${email}`: `/store-detail/${store['id']}`}>
                           {store['storeName']}
                           </Link>
                          </h2>

                          {/* <div>
                            <span className="font-small text-muted">
                              By <a href="vendor-details-1.html">NestFood</a>
                            </span>
                          </div> */}
                          <div className="product-card-bottom">
                            <div className="product-price">
                              {/* <span>{product['unitPrice']}{" "}{product['currencyType']}</span> */}
                              {/* <span className="old-price">$32.8</span> */}
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {isPreLoading && (
                <div className="spinner-container-v1">
                  <div className="spinner-v1"></div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
