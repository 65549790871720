import React, {
    useEffect,
    useState
} from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import moment from "moment";
interface Product {
    // Define your product properties
}

interface Order {
    orderID: string;
    orderDate: string;
    amount: number;
    paymentCurrency: string;
    orderStatus: string;
    products: Product[];
    // Add other order properties
}

export const MyOrdersV2 = ({ publicKey, userData, isApp, isAppEmailAddress }) => {
    const { email } = useParams();
    const [allOrders, setAllOrders] = useState<Order[]>([]);


    useEffect(() => {
        async function data() {
            await getMyOrders();
        }
        data();
        console.log(email)
    }, [email]);



    const getMyOrders = async () => {
        let payload = {};
        // if (publicKey) {
        //     payload = { "role": 'end-user', 'walletID': publicKey.toString()}
        // }else if(email){
        //     payload = { "role": 'end-user', 'emailId': email}
        // }
        payload = { "role": 'end-user', 'emailId': email }
        try {
            console.log(userData)
            const response = await fetch(process.env.REACT_APP_API_URL + "GetMyOrdersByEmail",
                {
                    method: "POST",
                    mode: "cors",
                    headers: new Headers({ "content-type": "application/json" }),
                    body: JSON.stringify(payload),
                }
            );
            if (response.status === 200) {
                const data = await response.json();
                console.log(data);
                setAllOrders(data['orders']);
                // setAllProducts(data['orders']['products']);

            } else {
                console.log(response.status);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // Set up the interval to call getMyOrders every 10 seconds
        const intervalId = setInterval(() => {
            console.log("refreshing")
            getMyOrders();
        }, 60000); // 10000 milliseconds = 10 seconds

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures that useEffect runs only once on mount


    return (
        <>
            <div className="check-out-main">
                <div className="check-head">
                    <Row>

                        <Col sm={6}>
                            <div className="checkout-subhead">
                                <h2>My Orders</h2>
                            </div>
                            {allOrders.length > 0 &&
                                <div className="checkout-product mobile-checkout-product">
                                    {allOrders.map((order, orderIndex) => (
                                        <Card className="checkout-card">
                                            <Card.Body className="checkout-card-body">
                                                <div className="row w-100 no-gutters">
                                                    <div className="col-12 col-md-3 mb-2">
                                                        {order.products && order.products.length > 0 && (
                                                            <>
                                                                <h6 className="text-charcoal mb-0 w-100">Products</h6>
                                                                {order.products.map((product, productIndex) => (
                                                                    <div className="fs-6 fw-bold">
                                                                        <Link
                                                                            to={{ pathname: "/store-product-detail/" + product['id'] + "/" + email }}
                                                                            state={{ from: "my-orders" }}
                                                                        >
                                                                            {product['productName']}
                                                                        </Link>
                                                                        {" "} - <span className="text-pebble"> {product['productQuantity']} Qty</span>
                                                                        {/* <h6 className="text-charcoal mb-0 w-100">Color : </h6> {}  */}
                                                                    </div>

                                                                ))}
                                                            </>
                                                        )}

                                                    </div>
                                                    <hr />
                                                    <div className="col-6 col-md">
                                                        <h6 className="text-charcoal mb-0 w-100">Order Number</h6>
                                                        <p className="text-pebble mb-0 w-100 mb-2 mb-md-0">#{order['orderID']}</p>
                                                    </div>
                                                    <div className="col-6 col-md">
                                                        <h6 className="text-charcoal mb-0 w-100">Date</h6>
                                                        {order["orderDate"] && (<p className="text-pebble mb-0 w-100 mb-2 mb-md-0">{moment(order["orderDate"]).local().format('DD-MM-YYYY hh:mm:ss A')}</p>)}
                                                    </div>
                                                    <div className="col-6 col-md">
                                                        <h6 className="text-charcoal mb-0 w-100">Total</h6>
                                                        <p className="text-pebble mb-0 w-100 mb-2 mb-md-0 fw-bold">{order['amount']} {order['paymentCurrency']}</p>
                                                    </div>
                                                    <div className="col-6 col-md">
                                                        <h6 className="text-charcoal mb-0 w-100">Order Status</h6>
                                                        <p className="text-pebble mb-0 w-100 mb-2 mb-md-0 fw-bold">{order['orderStatus']}</p>
                                                    </div>

                                                    <div className="col-6 col-md">
                                                        <h6 className="text-charcoal mb-0 w-100">Transaction Hash</h6>
                                                        <p className="text-pebble mb-0 w-100 mb-2 mb-md-0 fw-bold">
                                                            <a href={"https://solscan.io/tx/" + order['transactionHash']} target="_blank">View on solscan</a>
                                                        </p>
                                                    </div>
                                                </div>



                                            </Card.Body>
                                        </Card>
                                    ))}
                                </div>
                            }
                        </Col>
                        {/* <Col sm={6}>
                 <div className="disclaimer">
                   <p>
                     Your information is collected for shipping purposes only.
                     Monkey Commerce does not store any of the below
                     information.
                   </p>
                 </div>
               </Col> */}
                    </Row>
                </div>
            </div>
        </>
    );
};
