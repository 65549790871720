import { useState, useMemo, useCallback } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CartProvider } from './components/CartContext';
import { AppRoutes } from "./app-routes";
import { AppRoutesMobile } from "./app-routes-sparqy";
import { HelioPay } from "@heliofi/react";
// import './variables.css'
import './variables-sparqy.css'

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

function App() {
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new GlowWalletAdapter(),
      new MathWalletAdapter(),
    ],
    []
  );

  const endpoint = useMemo(() => clusterApiUrl("mainnet-beta"), []);
  
  const RoutesComponent = process.env.REACT_APP_FOR === 'sparqy' ? AppRoutesMobile : AppRoutes;

  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //     <HelioPay
    //     cluster="mainnet"
    //     payButtonTitle="PAY"
    //     paymentRequestId="64e45a79a33738225c7b440c"
    //     supportedCurrencies={["USDC", "SOL", "DUST", "BOO", "HALO"]}
    //     totalAmount={0.145}
    //   />
    //   </header>
    // </div>
    <>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
          <CartProvider>
            <BrowserRouter>
              <RoutesComponent />
            </BrowserRouter>
            </CartProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </>
    // <>
    //  <BrowserRouter>

    //  <AppRoutes />
    //  </BrowserRouter>

    // </>
  );
}

export default App;
