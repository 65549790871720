// CartContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface CartContextProps {
  cartCount: number;
  addToCart: (quantity: number) => void; // Updated addToCart function with a quantity parameter
  removeFromCart: (quantity: number) => void,
  initializeCartCount: (initialCount:number) => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);

  const addToCart = (quantity: number) => {
    setCartCount((prevCount) => prevCount + quantity);
  };
 
  const removeFromCart = (quantity: number) => {
    setCartCount((prevCount) => prevCount - quantity);
  };

  const initializeCartCount = (initialCount:number) => {
    // You can perform any logic to fetch the initial cart count from the server or local storage
   
    setCartCount(initialCount);
  };

  const value: CartContextProps = { cartCount, addToCart, initializeCartCount, removeFromCart };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};



export const useCart = (): CartContextProps => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
