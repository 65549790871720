import React, {
  useEffect,
  useCallback,
  useState,
  useReducer,
  useRef,
} from "react";

import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useProSidebar } from "react-pro-sidebar";
import { CompSideBar } from "../components/sidebar";
import { Line } from "react-chartjs-2";
import { Modal, Row, Col, Card, Button, Form, Stack } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Carousel from "react-grid-carousel";
import { HelioPay } from "@heliofi/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reach_us_img from "../images/contact-us.png";
import checkoutImg from "../images/checkout-img.png";
import storeAvatar from "../images/store-avatar.png";
import storeImg1 from "../images/store-img1.png";
import storeImg2 from "../images/store-img2.png";
import storeImg3 from "../images/store-img3.png";
import storeImg4 from "../images/store-img4.png";
import solanalogo from "../images/icons/solanalogo.png";
import facebook from "../images/icons/facebook-store.png";
import insta from "../images/icons/insta-store.png";
import twitter from "../images/icons/twitter-store.png";
import freeShipping from "../images/icons/free-shipping.png";
import easyReturn from "../images/icons/easy-return.png";
import securePayment from "../images/icons/secure-payment.png";
import support from "../images/icons/support.png";
import cartArrow from "../images/icons/cart-arrow.png";
import searchIcon from "../images/icons/search-icon.png";

import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import "bootstrap/dist/css/bootstrap.min.css";
import "./check-out.css";

export const HelioPayButton = ({ publicKey, userData, isApp, isAppEmailAddress }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [savedAddress, setSavedAddress] = useState(false);
  const [userAddress, setUserAddress] = useState({});
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [editAddress, setEditAddress] = useState({});
  const [savedUserData, setSavedUserData] = useState([{}]);
  const [activeAddress, setActiveAddress] = useState({});
  const [otherAddress, setOtherAddress] = useState([{}]);
  const [userId, SetUserId] = useState("");
  const [allProduct, setAllProduct] = useState([]);
  const [productQuantity, setProductQuantity] = useState(1);
  const [subTotal, setSubTotal] = useState(0.0);
  const [paymentcurrency, setPaymentCurrency] = useState(['SOL']);
  const [shippingCost, setShippingCost] = useState(0);
  const [tax, setTax] = useState(18);
  const [taxValue, setTaxValue] = useState(0);

  const { email } = useParams();
  const location = useLocation();
  const isWebView = location.search.includes('view=webview');






  const getProductsByUserID = async () => {
    console.log(email);
    if (email) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "getShoppingCart",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ emailId: email }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          setAllProduct(data["productData"]);
          // setSubTotal(data["cartSubTotal"]);
          let total = 0;
          data['productData'].forEach(product => {
            total = total + Number(product['cartPrice'])
          });
          setSubTotal(total)
          setPaymentCurrency[0] = (data["productData"][0]["productSelectedPayment"].toString())
          const userdata = data["userAddress"];
          if (userdata[0]["country"] == "india") {
            setShippingCost(
              parseInt(data["productData"][0]["shippingCostIndia"])
            );
          } else {
            setShippingCost(
              parseInt(data["productData"][0]["shippingCostOtherCountry"])
            );
          }
        } else {
          console.log(response.status);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };


  useEffect(() => {
    async function data() {
      await getProductsByUserID();
    }
    data();
  }, [email]);

  const paymentSuccess = async (e) => {
    console.log(e);
    let total_amt = subTotal + shippingCost + taxValue;
    let order_details = {
      products: allProduct,
      storeID:allProduct[0]['storeID'],
      emailId: email,
      amount: total_amt,
      transactionHash: e['data']['transactionSignature'],
      paymentCurrency: allProduct[0]["acceptedCurrency"]
    };
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "CreateOrder", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(order_details),
      });
      if (response.status === 200) {
        const data = await response.json();
        console.log(data);
        toast("Order Placed Successfully", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.log(response.status);
        toast.error(response.status, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e: any) {
      console.log(e);
      toast.error(e, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      {true ? (
        <>
          <ToastContainer />
          <div className="check-out-main">
            <div className="check-head">
              <Row>

                <Col sm={6}>
                  <div className="checkout-subhead">
                    <h2>Order Summary</h2>
                  </div>

                  <div className="checkout-product mobile-checkout-product">
                    <Card className="checkout-card">
                      <Card.Body className="checkout-card-body">

                        <div className="product-subtotal">
                          <h5>
                            SubTotal <span>{(subTotal + taxValue).toFixed(2)} {allProduct[0]?.['productSelectedPayment']}</span>
                          </h5>
                          <h5>
                            Shipping <span>{shippingCost} {allProduct[0]?.['productSelectedPayment']}</span>
                          </h5>
                        </div>
                        <div className="product-total">
                          <h3>
                            Total{" "}
                            <span>
                              {(subTotal + shippingCost + taxValue).toFixed(2)} {allProduct[0]?.['productSelectedPayment']}
                            </span>
                          </h3>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
                {/* <Col sm={6}>
                  <div className="disclaimer">
                    <p>
                      Your information is collected for shipping purposes only.
                      Monkey Commerce does not store any of the below
                      information.
                    </p>
                  </div>
                </Col> */}
              </Row>
            </div>
            <div className="checkout-content">
              <Row>
                {allProduct.length > 0 &&
                  <HelioPay
                    cluster="mainnet"
                    payButtonTitle="Pay and Place Order"
                    paymentRequestId="653f49e1f8042a6c66936519"
                    supportedCurrencies={[allProduct[0]?.['productSelectedPayment']]}
                    // totalAmount={0.0001}
                    totalAmount={subTotal + shippingCost + taxValue}
                    onSuccess={function (event): void {
                      console.log("onSuccess", event);
                      paymentSuccess(event);
                    }}
                    onError={function (event: any): void {
                      console.log("onError", event);
                      toast.error(event, {
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                      });
                    }}
                    onPending={function (event): void {
                      console.log("onPending", event);
                    }}
                    onStartPayment={function (): void {
                      console.log("onStartPayment");
                      toast("Payment Initiated", {
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                      });
                    }}
                  />
                }


              </Row>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start "
          >
            <div
              className={`${isMobile
                ? "px-5"
                : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                }`}
            >
              <div
                className={`${isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                  }`}
              >
                <div
                  className={`${isMobile
                    ? ""
                    : "d-flex flex-center flex-column flex-column-fluid"
                    }`}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="create-raffle-wallet-connet">
                        <img
                          className="reach-us-img"
                          alt=""
                          src={reach_us_img}
                        />
                        <div className="reach-us-to-whitelist">
                          Please connect your wallet.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="wallet-button">
                        <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
