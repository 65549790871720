import React, { FC, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Home } from "./pages/home";
import { Dashboard } from "./pages/dashboard";
import { PageNotFound } from "./pages/page-not-found";
import { StoreProductDetail } from "./pages/store-product-detail";
import { StoreDetail } from "./pages/store-detail";
import { CheckOut } from "./pages/check-out";
import { ShoppingCart } from "./pages/shopping-cart";
import { MyOrders } from "./pages/my-orders";
import { OrderDetails } from "./pages/order-details";
import { MyCart } from "./pages/my-cart";
import { MyAddress } from "./pages/my-address";
import { HelioPayButton } from "./pages/helio-pay";
import { MyOrdersV2 } from "./pages/my-orders-v2"
// import { Navigation } from "./Navigation";

const AppRoutesMobile: FC = () => {
  const { publicKey } = useWallet();
  const [userData, setUserData] = useState({});
  const [userRole, SetUserRole] = useState("end-user");
  const [isApp, SetIsApp] = useState(false);
  const [isAppEmailAddress, SetIsAppEmailAddress] = useState('');


  useEffect(() => {
    getUser();
  }, [publicKey]);

  const getUser = async () => {
    console.log(isAppEmailAddress)
    if (publicKey) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "getUser", {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({ walletID: publicKey.toString() }),
        });
        if (response.status === 200) {
          const data = await response.json();
          console.log(data)
          setUserData(data['userData']);
          SetUserRole(data['userData']["role"]);
          console.log(data['userData']['role']);
        } else {
          console.log(response.status);
        }
      } catch (e: any) {
        console.log(e);
      }
    } else if (isAppEmailAddress) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "getUser", {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({ emailID: isAppEmailAddress }),
        });
        if (response.status === 200) {
          const data = await response.json();
          console.log(data)
          setUserData(data['userData']);
          SetUserRole(data['userData']["role"]);
          console.log(data['userData']['role']);
        } else {
          console.log(response.status);
        }
      } catch (e: any) {
        console.log(e);
      }
    }

  };

  return (
    <>
      <div className="">
        <div className="">
          <div className="">
            <Header publicKey={publicKey}/>

            <Routes>

              <Route path="/:email?" element={<Home />} />
              <Route path="/store-detail/:id/:email?" element={<StoreDetail isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />
              <Route
                path="/store-product-detail/:id/:email?"
                element={<StoreProductDetail isApp={isApp} isAppEmailAddress={isAppEmailAddress} publicKey={publicKey} />}
              />
              <Route path="/cart-check-out/:email?" element={<CheckOut publicKey={publicKey} userData={userData} isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />
              <Route path="/shopping-cart/:email?" element={<ShoppingCart isApp={isApp} isAppEmailAddress={isAppEmailAddress} publicKey={publicKey}/>} />
              <Route path="/dashboard/:email?" element={<Dashboard publicKey={publicKey} />} />
              <Route path="/order-detail/:id/:email?" element={<OrderDetails publicKey={publicKey} userData={userData} />} />
              <Route path="/my-cart/:email?" element={<MyCart publicKey={publicKey} />} />
              <Route path="/my-address/:email?" element={<MyAddress publicKey={publicKey} />} />
              <Route path="/pay/:email?" element={<HelioPayButton publicKey={publicKey} userData={userData} isApp={isApp} isAppEmailAddress={isAppEmailAddress}/>} />
              <Route path="/my-orders/:email?" element={<MyOrdersV2 publicKey={publicKey} userData={userData} isApp={isApp} isAppEmailAddress={isAppEmailAddress}/>} />
            </Routes>
            {process.env.REACT_APP_FOR != 'sparqy' &&
              <Footer />
            }
            
          </div>
        </div>
      </div>
    </>
  );
};

export { AppRoutesMobile };
