import React, {
    useEffect,
    useCallback,
    useState,
    useReducer,
    useRef,
  } from "react";
  
  import { useNavigate } from "react-router-dom";
//   import { useProSidebar } from "react-pro-sidebar";
  import { CompSideBar } from "../components/sidebar";
  import { Line } from "react-chartjs-2";
  import { Modal, Row, Col, Card, Button, Form, Stack } from "react-bootstrap";
  import Tab from 'react-bootstrap/Tab';
  import Tabs from 'react-bootstrap/Tabs';
  import Carousel from 'react-grid-carousel'
  import reach_us_img from "../images/contact-us.png";
  import checkoutImg from "../images/checkout-img.png";
  import storeAvatar from "../images/store-avatar.png";
  import storeImg1 from "../images/store-img1.png";
  import storeImg2 from "../images/store-img2.png";
  import storeImg3 from "../images/store-img3.png";
  import storeImg4 from "../images/store-img4.png";
  import solanalogo from "../images/icons/solanalogo.png";
  import facebook from "../images/icons/facebook-store.png";
  import insta from "../images/icons/insta-store.png";
  import twitter from "../images/icons/twitter-store.png";
  import freeShipping from "../images/icons/free-shipping.png";
  import easyReturn from "../images/icons/easy-return.png";
  import securePayment from "../images/icons/secure-payment.png";
  import support from "../images/icons/support.png";
  import cartArrow from "../images/icons/cart-arrow.png";
  import searchIcon from "../images/icons/search-icon.png";
  import quantityminus from "../images/icons/quantityminus.png";
  import quantityplus from "../images/icons/quantityplus.png";


  import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
  import {
    WalletDisconnectButton,
    WalletMultiButton,
  } from "@solana/wallet-adapter-react-ui";

  import "bootstrap/dist/css/bootstrap.min.css";
  import "./shopping-cart.css";
  import "./my-cart.css";

export const MyCart = ({publicKey}) => {
    const navigate = useNavigate();
    // const { collapseSidebar } = useProSidebar();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    const [allProduct, setAllProduct] = useState([]);
    const [productQuantity, setProductQuantity] = useState(1);
    const [subTotal, setSubTotal] = useState(0.0);
    const [tax, setTax] = useState(18);
    const [taxValue, setTaxValue] = useState(0);
    const [userId, SetUserId] = useState('');


    const increaseProductQuantity = async (e) => {
        setProductQuantity(productQuantity + 1);
        var cartID = e.target.id;
        var productQuantityValue = parseInt(e.target.alt) + 1;
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "updateShoppingCart",
              { 
                  method: "POST",
                  mode: "cors",
                  headers: new Headers({ "content-type": "application/json" }),
                  body: JSON.stringify({ id: cartID, quantity:productQuantityValue }),
              }
            );
            if (response.status === 200) {
                getProductsByUserID();
                // window. location. reload();
            } else {
              console.log(response.status);
            }
          } catch (e) {
              console.log(e);
          }
    }
    const decreaseProductQuantity = async (e) => {
        setProductQuantity(productQuantity - 1)
        var cartID = e.target.id;
        var productQuantityValue = parseInt(e.target.alt) - 1;
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "updateShoppingCart",
              { 
                  method: "POST",
                  mode: "cors",
                  headers: new Headers({ "content-type": "application/json" }),
                  body: JSON.stringify({ id: cartID, quantity:productQuantityValue }),
              }
            );
            if (response.status === 200) {
                getProductsByUserID();
                // window. location. reload();
            } else {
              console.log(response.status);
            }
          } catch (e) {
              console.log(e);
          }
    }
    const RemoveCartProduct = async (e) => {
        console.log(e.target.id);
        var cartID = e.target.id;
        const response = await fetch(process.env.REACT_APP_API_URL + "deleteCart", {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({id:cartID}),
        });
        if (response.status === 200) {
            getProductsByUserID();
        } else {
            console.log();
        }
    }
    function getTax(){
        var taxCal = (tax*subTotal)/100;
        setTaxValue(taxCal);
    }
    
    const getProductsByUserID = async () => {
        console.log(userId);
        if(userId){
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "getShoppingCart",
                    { 
                        method: "POST",
                        mode: "cors",
                        headers: new Headers({ "content-type": "application/json" }),
                        body: JSON.stringify({ walletId: userId }),
                    }
                );
                if (response.status === 200) {
                    const data = await response.json();
                    console.log(data);
                    setAllProduct(data['productData']);
                    setSubTotal(data['cartSubTotal']);
                } else {
                    console.log(response.status);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };
      const redirect = () => {
        navigate("/cart-check-out");
      }
    // useEffect(() => {
    //     // var url_path = window.location.pathname;
    //     // console.log(url_path);
    //     // var product_id = url_path.split("/")[2];
    //     // console.log(product_id);
    //     SetUserId("93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi");

    //   }, [navigate]);
    useEffect(() => {
        async function taxdata() {
          await getTax();
        }
        taxdata();
      }, [navigate, userId, subTotal]);
      useEffect(() => {
        async function data() {
            SetUserId("93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi");
            await getProductsByUserID();
        }
        console.log("use effect running");
        data();
      }, [navigate, userId]);
      
    return(
        <>
            {true ? (
            <>
                <div>
                    <CompSideBar publicKey={publicKey} />
                </div>
                <div className="mainpage-dashboard">
                    <div className="cart-head">
                        <Row>
                            <Col sm={12}>
                                <div className="cart-heading">
                                    <h1>Shopping Cart <span className="mycart-checkout"><Button className="shadow-btn shadow-btn-width margin0" variant="primary" onClick={redirect}>CHECK OUT <img src={cartArrow}/></Button></span></h1>
                                    
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="cart-content">
                        <Row>
                            <Col sm={12}>
                                <div>
                                    <table className="cart-table">
                                        <thead className="cart-table-head">
                                            <tr>
                                                <th>Product</th>
                                                <th>Size</th>
                                                <th>Quantity</th>
                                                <th>Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody className="cart-table-body">
                                            {allProduct.map((product, productIndex) => (
                                                <tr key={productIndex}>
                                                    <td>
                                                        <Row>
                                                            <Col sm={3}>
                                                                <img src={checkoutImg}/>
                                                            </Col>
                                                            <Col sm={9}>
                                                                <h3>{product['productName']}</h3>
                                                                <h6>Colour: {product['selectedColor']}</h6>
                                                                <h4 className="product-remove" id={product['cartId']} onClick={RemoveCartProduct}>Remove</h4>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td className="table-productSelectedSize">
                                                        {product['productSelectedSize']}
                                                        {/* <Form.Control as="select" className="form-input" size="sm" type="text" placeholder="Select Country *" >
                                                            <option selected value="S">S</option>
                                                            <option value="M">M</option>
                                                            <option value="L">L</option>
                                                        </Form.Control> */}
                                                    </td>
                                                    <td>
                                                        <div className="quantity">
                                                            <img id={product['cartId']} src={quantityminus} alt={product['productQuantity']} onClick={decreaseProductQuantity}/>
                                                            <h1>{product['productQuantity']}</h1>
                                                            <img id={product['cartId']} src={quantityplus} alt={product['productQuantity']} onClick={increaseProductQuantity}/>
                                                        </div>
                                                    </td>
                                                    <td className="table-row-total">{product['acceptedCurrency']} {product['price']}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>) : (
            <>
                <div
                id="kt_content_container"
                className="d-flex flex-column-fluid align-items-start "
                >
                    <div
                    className={`${
                        isMobile
                        ? "px-5"
                        : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                    }`}
                    >
                    <div
                        className={`${
                        isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                        }`}
                    >
                        <div
                        className={`${
                            isMobile
                            ? ""
                            : "d-flex flex-center flex-column flex-column-fluid"
                        }`}
                        >
                        <div className="row">
                            <div className="col-12">
                            <div className="create-raffle-wallet-connet">
                                <img
                                className="reach-us-img"
                                alt=""
                                src={reach_us_img}
                                />
                                <div className="reach-us-to-whitelist">
                                Please connect your wallet.
                                </div>
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="wallet-button">
                                <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </>
        )}
        </>
    )
}